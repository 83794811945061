<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignBdoCardForm"
        ref="assignBdoCardForm"
        class="custom-form pt-6"
        @submit.prevent="assign"
      >
        <v-col cols="12">
          <v-text-field
            :value="bdoCardClient && bdoCardClient.name"
            label="Klient (tylko do odczytu)"
            :placeholder="bdoCardClient ? 'Klient' : 'Brak'"
            outlined
            dense
            readonly
          />
        </v-col>

        <v-col cols="12">
          <v-menu
            v-model="showDatePicker"
            v-bind="attributes.menu"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedValue"
                label="Data"
                placeholder="Wybierz datę"
                v-bind="{...attrs, ...attributes.textField}"
                v-on="on"
              >
                <template #prepend-inner>
                  <Icon
                    name="date"
                    size="small"
                    class="mr-2"
                  />
                </template>
                <template #append>
                  <SwitchDayButtons
                    :value="date"
                    @input="[date = $event, delayedFetchCourses()]"
                  />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              v-bind="attributes.datePicker"
              @input="showDatePicker = false"
              @change="fetchCourses"
            />
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="coursesClient"
            :items="clients"
            :search-input.sync="searchClientQuery"
            item-value="id"
            item-text="name"
            label="Kursy według klienta"
            placeholder="Szukaj"
            :loading="loadingClients"
            return-object
            clearable
            outlined
            dense
            @input="fetchCourses"
            @click:clear="courseId = null"
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="courseId"
            :items="parsedCourses"
            item-value="id"
            :item-text="getCourseLabel"
            :rules="[rules.required]"
            :loading="loadingCourses"
            label="Kurs"
            placeholder="Wybierz kurs"
            outlined
            dense
          />
        </v-col>

        <v-col
          cols="12"
          class="pt-0 mt-n3"
        >
          <v-checkbox
            v-model="showAllCourses"
            label="Pokaż kursy wszystkich klientów"
            hide-details
            class="mt-0"
            @change="fetchCourses"
          />
        </v-col>

        <v-col
          cols="12"
          class="pt-2 mt-n3"
        >
          <v-checkbox
            v-model="finished"
            label="Pokaż tylko zakończone kursy"
            hide-details
            class="mt-0"
            @change="fetchCourses"
          />
        </v-col>
        <v-col
          cols="12"
          class="pt-0"
        >
          <Warning
            v-if="courseAlreadyHasAssignedCard"
            message="Wybrany kurs ma już przypisaną kartę, która zostanie odpięta."
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="loadingClients || loadingCourses"
        type="submit"
        form="assignBdoCardForm"
      >
        Przypisz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import { dateStringFormat } from '../../utils'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import debounce from 'lodash/debounce'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      attributes,
      showDatePicker: false,
      courseId: null,
      bdoCardClient: null,
      clients: [],
      coursesClient: null,
      searchClientQuery: '',
      finished: false,
      showAllCourses: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapState({
      courses: state => state.courses.items,
      department: state => state.core.department,
      bdoCard: state => state.bdoCard.entity,
      isProcessing: state => state.bdoCards.isProcessing,
      loadingClients: state => state.client.isProcessing,
      loadingCourses: state => state.courses.isProcessing,
    }),
    parsedCourses() {
      return this.courses.filter(course => course?.courseType !== 'Podstawienie')?.map(course => course.swapCourse || course)
    },
    courseAlreadyHasAssignedCard() {
      if (!this.courseId) return false
      return !!this.parsedCourses.find(course => course.id === this.courseId)?.bdoNumber
    },
    formattedValue: {
      get () {
        return this.date ? dateStringFormat(this.date) : ''
      },
      set (value) {
        this.date = value
      }
    }
  },
  watch: {
    searchClientQuery (val) {
      if (!val || val.length < 3 || this.loadingClients) return
      this.fetchClients(val)
    },
  },
  created () {
    this.bdoCardClient = this.bdoCard?.client
    if (this.bdoCardClient) {
      this.searchClientQuery = this.bdoCardClient.name
      this.coursesClient = this.bdoCardClient
    }
    this.fetchCourses()
  },
  methods: {
    ...mapActions({
      getCourses: 'courses/getItems',
      getClientsByQuery: 'client/getClientsByQuery',
      assignCourseToBdoCard: 'bdoCard/assignCourseToBdoCard',
    }),
    fetchClients: debounce(function (query) {
      this.getClientsByQuery(query)
        .then((clients) => {
          this.clients = clients
        })
    }, 700),
    fetchCourses () {
      const params = {
        departmentId: this.department.id,
        filters: {
          withoutCards: true,
          withSwapBase: true,
          bdoAssignment: true,
          clientId: this.coursesClient?.id,
          daterange: this.date ? [this.date] : undefined,
          finished: this.finished || null,
        }
      }
      if (this.showAllCourses) delete params.filters.clientId

      this.getCourses({ params })
        .then(() => {
          if (!this.parsedCourses.find(course => course.id === this.courseId)) this.courseId = null
        })
    },
    delayedFetchCourses: debounce(function() { this.fetchCourses() }, 1000),
    getCourseLabel(item) {
      const { bdoCardNumber, orderRefNumber, containerWithType, orderAddressDisplayName, clientName } = item || {}
      return `${orderAddressDisplayName}, ref: ${orderRefNumber}, ${containerWithType}${bdoCardNumber ? (' Przypisana karta: ' + bdoCardNumber) : ''} ${clientName ? (' Klient: ' + clientName) : ''}`
    },
    assign () {
      if (this.$refs.assignBdoCardForm.validate()) {
        this.assignCourseToBdoCard({ courseId: this.courseId })
          .then(() => { this.afterFormSubmitted('Przypisano kartę') })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: normal !important;
}
</style>
